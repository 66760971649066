<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">课程参数</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ stu == "edit" ? "查看" : "新增" }}班级课程参数</a>
        </span>
      </div>
      <div class="framePage-body seeClassCurriculumParameterInfo">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexcc">
              <el-form ref="Form" :model="Form" :rules="rules" label-width="14rem">
                <el-form-item label="行政区划" prop="areaId" class="form-item">
                  <el-input v-if="Form.areaId == '-1000'" v-model="areaName" disabled style="width: 250px" />
                  <el-cascader disabled v-else v-model="Form.areaId" style="width: 250px" :options="areatreeList"
                    :props="propsarea" clearable size="small"></el-cascader>
                </el-form-item>
                <el-form-item label="培训类型" prop="trainTypeId" class="form-item">
                  <el-input v-if="Form.trainTypeId == '-1000'" v-model="trainTypeName" disabled style="width: 250px" />
                  <el-cascader disabled v-else style="width: 250px" size="small" :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }" v-model="Form.trainTypeId" :options="trainTypeList" clearable
                    @change="handleTrainType"></el-cascader>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsLogin" class="form-item" label="实名认证必备条件">
                  <el-radio-group @change="handleclear()" v-model="Form.isIdCard" disabled>
                    <el-radio :label="true">身份证照片</el-radio>
                    <el-radio :label="false">身份证号</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="idCardVerifyMode" class="form-item" v-if="!Form.isIdCard" label="验证方式">
                  <el-radio-group v-model="Form.idCardVerifyMode" disabled>
                    <el-radio :label="10">默认</el-radio>
                    <el-radio :label="20">公安认证</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsEventLimit" class="form-item" label="弹窗停留限制">
                  <el-radio-group v-model="Form.paraIsEventLimit" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="弹窗停留超过" v-if="Form.paraIsEventLimit" prop="paraIsEventLimitTime">
                  <el-input-number size="small" v-model="Form.paraIsEventLimitTime" :step="1" :min="1"
                    style="margin-right: 5px"></el-input-number>
                  <span>分钟后</span>
                </el-form-item>
                <el-form-item class="form-item" label="" v-if="Form.paraIsEventLimit" prop="paraIsEventLimitMode">
                  <el-radio-group v-model="Form.paraIsEventLimitMode">
                    <el-radio :label="10">返回上一级</el-radio>
                    <el-radio :label="20">原界面锁定</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsLoginEveryDay" class="form-item" label="每日学习签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsLoginEveryDay" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="Form.paraIsLoginEveryDay">
                  <el-form-item prop="paraIsLoginEveryDayMode" class="form-item" label="每日签到方式设置">
                    <el-radio-group disabled v-model="Form.paraIsLoginEveryDayMode">
                      <el-radio :label="10">人脸</el-radio>
                      <el-radio :label="20">弹窗</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <template v-if="Form.paraIsLoginEveryDayMode == '20'">
                    <el-form-item prop="paraIsLoginEveryDayPopupFirst" class="form-item" label="是否第一次为人脸签到">
                      <el-radio-group disabled v-model="Form.paraIsLoginEveryDayPopupFirst">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="提示语" class="form-item" prop="paraIsLoginEveryDayPopupMessage">
                      <el-input disabled v-model="Form.paraIsLoginEveryDayPopupMessage" type="textarea"
                        style="width: 15rem"></el-input>
                    </el-form-item>
                  </template>
                </template>
                <el-divider></el-divider>
                <el-form-item prop="paraIsLogin" class="form-item" label="课件是否人脸签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsLogin" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsLoginEvery" v-if="Form.paraIsLogin == true" class="form-item"
                  label="课件未学完是否每次人脸签到">
                  <el-radio-group v-model="Form.paraIsLoginEvery" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsLoginFinish" v-if="Form.paraIsLogin == true" class="form-item"
                  label="课件学完后是否人脸签到">
                  <el-radio-group disabled v-model="Form.paraIsLoginFinish">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="Form.paraIsLogin">
                  <el-form-item prop="paraIsLoginMode" class="form-item" label="签到方式">
                    <el-radio-group disabled v-model="Form.paraIsLoginMode">
                      <el-radio :label="10">人脸</el-radio>
                      <el-radio :label="20">弹窗</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <template v-if="Form.paraIsLoginMode == '10'">
                    <el-form-item prop="paraIsLoginBioassay" v-if="Form.paraIsLogin == true" class="form-item"
                      label="是否活体认证">
                      <el-radio-group disabled v-model="Form.paraIsLoginBioassay">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </template>
                  <template v-if="Form.paraIsLoginMode == '20'">
                    <el-form-item label="提示语" class="form-item" prop="paraIsLoginPopupMessage">
                      <el-input disabled v-model="Form.paraIsLoginPopupMessage" type="textarea"
                        style="width: 15rem"></el-input>
                    </el-form-item>
                  </template>
                </template>
                <el-divider></el-divider>
                <el-form-item prop="paraIsMiddle" class="form-item" label="课后习题是否签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsMiddle" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsMiddleEvery" v-if="Form.paraIsMiddle == true" class="form-item"
                  label="习题未通过是否每次签到">
                  <el-radio-group v-model="Form.paraIsMiddleEvery" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsMiddleBioassay" v-if="Form.paraIsMiddle == true" class="form-item"
                  label="是否活体认证">
                  <el-radio-group v-model="Form.paraIsMiddleBioassay" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsAfter" class="form-item" label="课程考试是否签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsAfter" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsAfterEvery" v-if="Form.paraIsAfter == true" class="form-item"
                  label="考试未通过是否每次签到">
                  <el-radio-group v-model="Form.paraIsAfterEvery" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsAfterBioassay" v-if="Form.paraIsAfter == true" class="form-item"
                  label="是否活体认证">
                  <el-radio-group v-model="Form.paraIsAfterBioassay" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="unitTestFace" class="form-item" label="单元测试是否签到">
                  <el-radio-group v-model="Form.unitTestFace" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="unitTestFaceBioassay" class="form-item" label="是否活体认证" v-if="Form.unitTestFace">
                  <el-radio-group v-model="Form.unitTestFaceBioassay" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="allowDrag" class="form-item" label="视频进度条可拖拽">
                  <el-radio-group v-model="Form.allowDrag" @change="paraIsDragChange" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsRoll" class="form-item" label="点名认证" v-show="!Form.allowDrag">
                  <el-radio-group v-model="Form.paraIsRoll" @change="paraIsRollChange" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRollSecond" class="form-item" label="点名读秒" v-show="Form.paraIsRoll">
                  <el-input el-input size="small" style="width: 200px" v-model="Form.paraRollSecond" readonly></el-input>
                  <span style="margin-left: 10px">秒</span>
                </el-form-item>
                <el-form-item prop="paraRollOrgFailureNum" class="form-item" label="机构点名失败" v-show="Form.paraIsRoll">
                  <el-input size="small" style="width: 200px" v-model="Form.paraRollOrgFailureNum" readonly></el-input>
                  <span style="margin-left: 10px">次失去资格</span>
                </el-form-item>
                <el-form-item prop="paraRollGovFailureNum" class="form-item" label="监管点名失败" v-show="Form.paraIsRoll">
                  <el-input size="small" style="width: 200px" v-model="Form.paraRollGovFailureNum" readonly></el-input>
                  <span style="margin-left: 10px">次失去资格</span>
                </el-form-item>
                <el-form-item prop="paraRollRandomNum" class="form-item" label="课件随机点名次数" v-show="Form.paraIsRoll">
                  <el-input size="small" style="width: 200px" readonly v-model="Form.paraRollRandomNum"></el-input>
                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-form-item prop="paraRollVoiceRemind" class="form-item" label="声音提醒" v-show="Form.paraIsRoll">
                  <el-radio-group v-model="Form.paraRollVoiceRemind" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsRollBioassay" class="form-item" label="是否活体认证" v-show="Form.paraIsRoll">
                  <el-radio-group v-model="Form.paraIsRollBioassay" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsSnapshots" class="form-item" label="人脸识别防挂课" v-show="!Form.allowDrag">
                  <el-radio-group v-model="Form.paraIsSnapshots" @change="paraIsLoginTipsChange" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraTimeMode" class="form-item" label="识别方式"
                  v-if="!Form.allowDrag && Form.paraIsSnapshots == true">
                  <el-radio-group v-model="Form.paraTimeMode" @change="paraTimeModeChange" disabled>
                    <el-radio label="10">按课件</el-radio>
                    <el-radio label="20">按课程</el-radio>
                    <el-radio label="30">指定课件</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsRandom" class="form-item"
                  v-if="Form.paraIsSnapshots == true && Form.paraTimeMode == '10'" label="人脸识别防挂课时长">
                  <el-radio-group @change="propclear" v-model="Form.paraIsRandom" disabled>
                    <el-radio :label="true">随机时间</el-radio>
                    <el-radio :label="false">指定时长</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == true && Form.paraTimeMode == '10'
                  " label="最小时间" prop="paraRandomTimeMin">
                  <el-input-number size="small" v-model="Form.paraRandomTimeMin" :step="1" :min="1"
                    style="margin-right: 5px" disabled></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == true && Form.paraTimeMode == '10'
                  " label="随机次数" prop="paraRandomNum">
                  <el-input-number size="small" v-model="Form.paraRandomNum" :step="1" :min="1" style="margin-right: 5px"
                    disabled></el-input-number>
                  <span>次</span>
                </el-form-item>
                <el-form-item class="form-item" prop="paraTime" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == false && Form.paraTimeMode == '10'
                  " label="最小时间">
                  <el-input-number v-model="Form.paraTime" :step="1" :min="1" size="small" disabled></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" prop="paraTimeNum" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == false && Form.paraTimeMode == '10'
                  " label="最大次数">
                  <el-input-number v-model="Form.paraTimeNum" :step="1" :min="0" size="small" disabled></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" prop="paraRandomFloatTime" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == false && Form.paraTimeMode == '10'
                  " label="浮动时间">
                  <el-input-number v-model="Form.paraRandomFloatTime" :step="1" :min="0" size="small"
                    disabled></el-input-number>
                  <span>分</span>
                </el-form-item>
                <el-form-item class="form-item" prop="paraTimeNum" v-if="Form.paraIsSnapshots == true && Form.paraTimeMode == '20'
                  " label="认证次数">
                  <el-input-number v-model="Form.paraTimeNum" :step="1" :min="0" size="small" disabled></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.paraIsSnapshots == true && Form.paraTimeMode == '30'
                  ">
                  第
                  <el-input v-model="Form.paraTime" size="small" disabled  style="width:200px"></el-input>课节，每节课
                  <el-input-number v-model="Form.paraTimeNum" :precision="0" :step="1" :min="1" :max="10" size="small" disabled></el-input-number>
                  次认证
                  <p style="color:red;">可以选多节课，逗号分隔开(应为逗号",")</p>
                </el-form-item>
                <el-form-item prop="paraIsRandom" class="form-item" v-if="Form.paraIsSnapshots == true"
                  label="是否活体认证">
                  <el-radio-group v-model="Form.paraIsSnapshotsBioassay" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraRecordVideo" class="form-item" label="是否录制视频">
                  <el-radio-group v-model="Form.paraRecordVideo" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRecordVideoMode" class="form-item" label="录制设置" v-if="Form.paraRecordVideo">
                  <el-radio-group v-model="Form.paraRecordVideoMode" @change="paraRecordVideoModeChange" disabled>
                    <el-radio :label="20">按课程</el-radio>
                    <el-radio :label="10">按课件</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRecordVideoNode" class="form-item" v-if="Form.paraRecordVideo" label="录制时间点">
                  <el-radio-group @change="propclear" v-model="Form.paraRecordVideoNode" disabled>
                    <el-radio label="0">开始时录制</el-radio>
                    <el-radio label="4">1/4时</el-radio>
                    <el-radio label="3">1/3时</el-radio>
                    <el-radio label="2">1/2时</el-radio>
                    <el-radio label="-1">随机录制</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRecordVideoTime" class="form-item" v-if="Form.paraRecordVideo == true"
                  label="录制时长">
                  <el-input-number v-model="Form.paraRecordVideoTime" :step="1" :min="5" :max="60" :precision="0" disabled
                    size="small"></el-input-number>

                  <span style="margin-left: 10px">秒</span>
                </el-form-item>
                <el-form-item prop="paraRecordVideoRandomNum" class="form-item" v-if="Form.paraRecordVideo &&
                  Form.paraRecordVideoNode == '-1'
                  " label="录制次数">
                  <el-input-number v-model="Form.paraRecordVideoRandomNum" :step="1" :min="1" :precision="0" size="small"
                    disabled></el-input-number>

                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-divider></el-divider>
                <!-- 甘肃  弹窗是否点名 -->
                <el-form-item prop="popupApproveEnable" class="form-item" label="弹窗是否点名">
                  <el-radio-group v-model="Form.popupApproveEnable" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="popupApproveIsRandom" class="form-item" v-if="Form.popupApproveEnable"
                  label="点名时间点">
                  <el-radio-group v-model="Form.popupApproveIsRandom" disabled>
                    <el-radio :label="true">随机时间</el-radio>
                    <el-radio :label="false">指定时长</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item class="form-item" v-if="Form.popupApproveEnable &&
                  Form.popupApproveIsRandom
                  " label="最小时间" prop="popupApproveRandomTimeMin">
                  <el-input-number size="small" v-model="Form.popupApproveRandomTimeMin" :step="1" :min="1"
                    style="margin-right: 5px" disabled></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.popupApproveEnable &&
                  Form.popupApproveIsRandom
                  " label="点名次数" prop="popupApproveRandomNum">
                  <el-input-number size="small" v-model="Form.popupApproveRandomNum" :step="1" :min="1"
                    style="margin-right: 5px" disabled></el-input-number>
                  <span>次</span>
                </el-form-item>
                <el-form-item class="form-item" prop="popupApproveFixedTime" v-if="Form.popupApproveEnable == true &&
                  Form.popupApproveIsRandom == false
                  " label="间隔时间">
                  <el-input-number v-model="Form.popupApproveFixedTime" :step="1" :min="1" size="small"
                    disabled></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item class="form-item" prop="popupApproveFixedNum" v-if="Form.popupApproveEnable == true &&
                  Form.popupApproveIsRandom == false
                  " label="点名次数">
                  <el-input-number v-model="Form.popupApproveFixedNum" :step="1" :min="0" size="small"
                    disabled></el-input-number>
                  <span>次</span>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.popupApproveEnable == true &&
                  Form.popupApproveIsRandom == false
                  " label="浮动时间" prop="popupApproveRandomFloatTime">
                  <el-input-number size="small" v-model="Form.popupApproveRandomFloatTime" :step="1" :min="0"
                    style="margin-right: 5px" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000'
                      "></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item label="提示语" class="form-item" prop="popupApproveHint" v-if="Form.popupApproveEnable">
                  <el-input v-model="Form.popupApproveHint" type="textarea" disabled style="width: 15rem"></el-input>
                </el-form-item>
                <el-divider></el-divider>
                <!-- 兰州  弹窗提示 -->
                <el-form-item prop="paraCountDownEnable" class="form-item" label="弹窗提示">
                  <el-radio-group v-model="Form.paraCountDownEnable" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item class="form-item" prop="paraCountDownDuration" v-if="Form.paraCountDownEnable"
                  label="弹窗提示停留时间">
                  <el-input-number v-model="Form.paraCountDownDuration" :step="1" :min="0" size="small"
                    disabled></el-input-number>
                </el-form-item>
                <el-form-item label="提示语" class="form-item" prop="paraCountDownHint" v-if="Form.paraCountDownEnable">
                  <el-input v-model="Form.paraCountDownHint" type="textarea" disabled style="width: 15rem"></el-input>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsPaper" class="form-item" label="强制课后练习">
                  <el-radio-group v-model="Form.paraIsPaper" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsAnalysis" class="form-item" label="课后习题显示答题解析">
                  <el-radio-group v-model="Form.paraIsAnalysis" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsExamAnalysis" class="form-item" label="课程考试显示答题解析">
                  <el-radio-group v-model="Form.paraIsExamAnalysis" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsUnitTest" class="form-item" label="强制单元测试">
                  <el-radio-group v-model="Form.paraIsUnitTest" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="unitTestAnalysis" class="form-item" label="单元测试显示答案解析">
                  <el-radio-group v-model="Form.unitTestAnalysis" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraShowAnalysis" class="form-item" label-width="14rem" label="课后习题答题结果显示答案解析">
                  <el-radio-group v-model="Form.paraShowAnalysis" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="unitTestShowAnalysis" class="form-item" label-width="14rem" label="单元测试答题结果显示答案解析">
                  <el-radio-group v-model="Form.unitTestShowAnalysis" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="graduationShowAnalysis" class="form-item" label-width="14rem" label="结业考试答题结果显示答案解析">
                  <el-radio-group v-model="Form.graduationShowAnalysis" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsEvaluate" class="form-item" label="是否强制评价">
                  <el-radio-group v-model="Form.paraIsEvaluate" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraOrderStudy" class="form-item" label="按顺序学习">
                  <el-radio-group v-model="Form.paraOrderStudy" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <!-- <el-form-item prop="paraCompleteCondition" class="form-item" label="特殊完成条件" >
                  <el-radio-group v-model="Form.paraCompleteCondition" disabled>
                    <el-radio :label="false">无</el-radio>
                    <el-radio :label="true">完成总视频时长</el-radio>
                  </el-radio-group>
                  <div class="paraCompleteConditionInput" v-if="Form.paraCompleteCondition">
                    <el-input v-model="Form.paraCompleteConditionNum" size="small" clearable readonly />%
                  </div>
                  <div style="font-size:14px;color: red; margin-left: 70px;" v-if="Form.paraCompleteCondition">*选择特殊完成条件的区域，请不要忘记修改该地区档案和学时证明模板</div>
                </el-form-item> -->
                <el-form-item prop="paraCompleteConditionT" class="form-item" label="特殊完成条件">
                  <el-radio-group v-model="Form.paraCompleteConditionT" @change="paraCompleteConditionTChange" disabled>
                    <el-radio :label="false">无</el-radio>
                    <el-radio :label="true">有</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraCompleteType" class="form-item" label="特殊完成类型"
                  v-if="Form.paraCompleteConditionT">
                  <el-radio-group v-model="Form.paraCompleteType" disabled>
                    <el-radio label="10">按视频时长</el-radio>
                    <el-radio label="20">按课时数量</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraCompleteConditionNum" class="form-item" label="特殊完成量"
                  v-if="Form.paraCompleteConditionT">
                  <el-input v-model="Form.paraCompleteConditionNum" size="small" style="width: 150px" clearable disabled>
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="每日学习时长限制">
                  <el-radio-group @change="handleclear()" v-model="Form.studyLength" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="学习时长限制类型" v-if="Form.studyLength">
                  <el-radio-group v-model="Form.xxsjlx" disabled>
                    <el-radio :label="10">按课时</el-radio>
                    <el-radio :label="30">按小时</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-if="Form.studyLength">
                  <div class="df" v-for="(item, index) in Form.paraTimeLimit" :key="index">
                    <div style="display: flex">
                      <el-form ref="Form1" :model="item" label-width="13rem" style="
                          display: flex;
                          flex-direction: row;
                          flex-wrap: nowrap;
                        ">
                        <el-form-item class="form-item" label="人群类型名称" prop="personType" :rules="[
                          {
                            required: Form.xxsjlx == '30' ? true : false,
                            message: '请选择人群类型名称',
                            trigger: 'change',
                          },
                        ]">
                          <el-select v-model="item.personType" clearable placeholder="请选择" style="width: 150px"
                            size="small" disabled @change="(val) => {
                                changePersonType(val, index);
                              }
                              ">
                            <el-option v-for="item1 in LearningstateList" :key="item1.value" :label="item1.label"
                              :value="item1.value"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item class="form-item" label-width="0" style="padding-left: 0">
                          <div style="margin-left: 20px" class="flexac">
                            <span style="margin-right: 10px">每天学习</span>
                            <el-input-number size="small" v-model="item.timeLimit" :precision="Form.xxsjlx == '30' ? 1 : 0"
                              :step="1" :min="1" disabled></el-input-number>
                            <!-- <span style="margin: 0 23px 0 5px">课时</span> -->
                            <span style="margin: 0 23px 0 5px">{{
                              Form.xxsjlx == 10 ? "课时" : "小时"
                            }}</span>
                            <!-- <el-button
                              v-if="Form.paraTimeLimit.length > 1"
                              size="mini"
                              @click="deleteItem(item, index)"
                              class="bgc-bv"
                              >删除</el-button
                            >
                            <el-button
                              v-if="index == Form.paraTimeLimit.length - 1"
                              size="mini"
                              @click="addItem"
                              class="bgc-bv"
                              :disabled="
                                Form.trainTypeId == '-1000' &&
                                Form.areaId == '-1000'
                              "
                              >新增</el-button
                            > -->
                          </div>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="考试次数是否限制">
                  <el-radio-group @change="handleclear()" v-model="Form.paraExamNumLimit" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="考试次数（次）" v-if="Form.paraExamNumLimit == true">
                  <el-input-number v-model="Form.paraExamMaxNum" :step="1" :min="1" size="small"
                    disabled></el-input-number>
                </el-form-item>
                <el-form-item label="提示语" class="form-item" v-if="Form.paraExamNumLimit == true" prop="paraExamHint">
                  <el-input v-model="Form.paraExamHint" type="textarea" style="width: 15rem" disabled></el-input>
                </el-form-item>
                <el-form-item class="form-item" label="最大可购买补考次数" v-if="Form.paraExamNumLimit == true">
                  <el-input-number v-model="Form.retakesAllowedNumber" :step="1" :min="0" size="small"
                    disabled></el-input-number>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="随机弹题">
                  <el-radio-group @change="handleparaIsPopQuestion" v-model="Form.paraIsPopQuestion" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraPopTimeLimit" class="form-item" label="弹题最小间隔时间" v-show="Form.paraIsPopQuestion">
                  <el-input-number v-model="Form.paraPopTimeLimit" :step="1" :min="1" :precision="0" size="small"
                    disabled></el-input-number>
                  <span style="margin-left: 10px">分钟</span>
                </el-form-item>
                <el-form-item prop="paraPopNum" class="form-item" label="每学时弹题次数" v-show="Form.paraIsPopQuestion">
                  <el-input-number v-model="Form.paraPopNum" :step="1" :min="1" :precision="0" size="small"
                    disabled></el-input-number>

                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="开班审批流程">
                  <el-radio-group v-model="Form.paraAuditType" disabled>
                    <el-radio label="10">平台审核后开班</el-radio>
                    <el-radio label="20">监管审核后开班</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="监管人群类型名称" v-if="Form.paraAuditType == '20'">
                  <div class="df">
                    <el-select v-model="Form.paraAuditCrowdType" clearable placeholder="请选择" style="width: 150px"
                      size="small" disabled>
                      <el-option v-for="item1 in LearningstateList2" :key="item1.value" :label="item1.label"
                        :value="item1.value"></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否开启学习时间段限制">
                  <el-radio-group v-model="Form.paraStudyTime" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="增加学习时间段限制" v-if="Form.paraStudyTime">
                  <el-time-picker size="small" is-range disabled arrow-control v-model="Form.studyTime"
                    range-separator="至" format="HH:mm" value-format="HH:mm:ss" :editable="false" start-placeholder="开始时间"
                    end-placeholder="结束时间" placeholder="选择时间范围">
                  </el-time-picker>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="班级培训结束后，允许学员继续观看视频">
                  <el-radio-group v-model="Form.paraFinishedWatch" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="观看天数" v-show="Form.paraFinishedWatch">
                  <el-input placeholder="请输入观看天数" v-model="Form.paraWatchDay" size="small" style="width: 200px">
                    <template slot="append">天</template>
                  </el-input>
                </el-form-item>
                <el-form-item class="form-item" label="是否启用字幕">
                  <el-radio-group v-model="Form.isShowSubtitle" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否需要签字">
                  <el-radio-group v-model="Form.paraSignature" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否开启弹窗管控">
                  <el-radio-group v-model="Form.enablePopupControls" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="弹窗频次" v-show="Form.enablePopupControls">
                  <span style="margin-right: 10px">每</span>
                  <el-input-number disabled size="small" v-model="Form.popupInterval" :step="1"
                    :min="1"></el-input-number>
                  <span style="margin-left: 10px">分钟</span>
                </el-form-item>
                <el-form-item class="form-item" label="提示内容" v-show="Form.enablePopupControls">
                  <el-input disabled v-model="Form.popupMessage" type="textarea" style="width: 15rem"></el-input>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否启用弹幕">
                  <el-radio-group v-model="Form.enableBarrage" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="是否启用评论">
                  <el-radio-group v-model="Form.paraIsComment" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="讨论变更为答疑">
                  <el-radio-group v-model="Form.isDiscussChangeAnswerquestion" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否允许下载">
                  <el-radio-group v-model="Form.enableDownload" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="是否允许下载题库">
                  <el-radio-group v-model="Form.enableDownloadQuestionbank" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="暂停最大记录次数">
                  <el-input-number disabled v-model="Form.paraSuspendNum" :step="1" :min="0"
                    size="small"></el-input-number>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="自动生成截屏备案记录">
                  <el-radio-group v-model="Form.autoScreenShot" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" v-show="Form.autoScreenShot" label="生成方式">
                  <el-radio-group v-model="Form.screenShotMode" disabled>
                    <el-radio :label="10">随机生成</el-radio>
                    <el-radio :label="20">按培训时间每天生成</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" v-show="Form.autoScreenShot&&Form.screenShotMode==10" label="生成截屏文件数量">
                  <el-input-number disabled v-model="Form.screenShotNum" :step="1" :min="0" size="small"></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" v-show="Form.autoScreenShot" label="截屏时段">
                  <el-select disabled v-model="Form.screenShotType" placeholder="请选择" style="width: 130px" size="small" >
                    <el-option v-for="item in ScreenList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="学习前上传证件照片">
                  <el-radio-group v-model="Form.isUploadIdPhoto" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否允许结业后考试">
                  <el-radio-group v-model="Form.paraIsGraduationAfterExam" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="结业考试限时">
                  <el-input style="width: 180px" placeholder="不输入默认结业考试不限时" v-model="Form.paraExamLimitTime"
                    @keyup.native="Form.paraExamLimitTime = zF.positiveInteger(Form.paraExamLimitTime)" size="small"
                    disabled></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="结业考试交卷时间限制">
                  <el-input style="width: 180px" placeholder="不输入默认结业考试不限时" v-model="Form.paraExamLimitMinTime"
                    @keyup.native="Form.paraExamLimitMinTime = zF.positiveInteger(Form.paraExamLimitMinTime)" size="small"
                    clearable disabled></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="课后习题限时">
                  <el-input style="width: 180px" placeholder="不输入默认课后习题不限时" v-model="Form.paraAnalysisLimitTime"
                    @keyup.native="Form.paraAnalysisLimitTime = zF.positiveInteger(Form.paraAnalysisLimitTime)"
                    size="small" disabled></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="课后练习交卷时间限制">
                  <el-input style="width: 180px" placeholder="不输入默课后练习试不限时" v-model="Form.paraAnalysisLimitMinTime"
                    @keyup.native="Form.paraAnalysisLimitMinTime = zF.positiveInteger(Form.paraAnalysisLimitMinTime)"
                    size="small" clearable disabled></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="单元测试限时">
                  <el-input style="width: 180px" placeholder="不输入默认单元测试不限时" v-model="Form.paraUnitTestLimitTime"
                    @keyup.native="Form.paraUnitTestLimitTime = zF.positiveInteger(Form.paraUnitTestLimitTime)"
                    size="small" disabled></el-input>
                  分钟
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否仅限本地IP学习">
                  <el-radio-group v-model="Form.paraIsLimitIp" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="档案学时设置">
                  <el-radio-group v-model="Form.convertClassHourType" disabled>
                    <el-radio :label="'10'">按课件数量</el-radio>
                    <el-radio :label="'20'">按视频时长换算</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="档案课时计算方式" v-if="Form.convertClassHourType == '20'">
                  <el-radio-group v-model="Form.convertClassHour" disabled>
                    <el-radio v-for="(item,index) in convertClassHourList" :key="index" :label="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
              </el-form>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">
                <!-- {{Form.trainTypeId != '-1000' && Form.areaId != '-1000' ? '取消': '返回'}} -->
                返回
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "SupervisionEnd/seeClassCurriculumParameterInfo",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    return {
      stu: "",
      areaName: "",
      trainTypeName: "",
      areatreeList: [],
      datatreeList: [], //培训类型
      LearningstateList: [], //人群类型
      LearningstateList2: [], //人群类型
      ScreenList: [], //截屏时段
      editJson: {},
      crodType: "",
      params: {},
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        areaId: "",
        trainTypeId: "",
        isIdCard: true,
        idCardVerifyMode: 10,
        paraIsLoginEveryDay: false,
        paraIsLoginEveryDayMode: 10,
        paraIsLoginEveryDayPopupFirst: false,
        paraIsLoginEveryDayPopupMessage: '根据上海监管要求，请确保为本人学习！',
        paraIsEventLimit: false,
        paraIsEventLimitTime: 1,
        paraIsEventLimitMode: 10,
        paraIsLoginMode: 10,
        paraIsLoginPopupMessage: false,
        paraIsLogin: false,
        paraIsLoginEvery: false,
        paraIsMiddle: false,
        paraIsMiddleEvery: false,
        paraIsAfter: false,
        paraIsRoll: false,
        paraRollSecond: "",
        paraRollOrgFailureNum: "",
        paraRollGovFailureNum: "",
        paraRollRandomNum: "2",
        paraRollVoiceRemind: false,
        paraIsSnapshots: false,
        paraTimeNum: 0,
        paraTimeMode: '10',
        paraIsPaper: false,
        unitTestFace: false,
        paraIsAnalysis: false,
        paraIsExamAnalysis: false,
        unitTestAnalysis: false,
        paraShowAnalysis: true,
        unitTestShowAnalysis: false,
        graduationShowAnalysis: false,
        paraIsEvaluate: false,
        paraOrderStudy: false,
        paraIsRandom: false,
        paraExamNumLimit: false,
        studyLength: false,
        paraTimeLimit: [],
        paraExamMaxNum: "",
        paraRandomTimeMin: "",
        paraRandomNum: "",
        paraTime: "",
        paraRandomFloatTime: '',
        paraExamHint: "",
        obj: {
          personType: "",
          timeLimit: "",
        },
        // paraSpecialConditions: false,// 完成进度是否
        // paraProgress:'80' // 完成进度输入框默认值
        paraCompleteCondition: false, //特殊完成条件
        paraCompleteConditionNum: "80", //百分比
        allowDrag: false,
        xxsjlx: 10, // 每日学习时长限制 - 学习时长限制类型
        retakesAllowedNumber: 0,
        paraSuspendNum: 0, //暂停最大记录次数
        paraIsComment: false, //是否启用评论
        //截屏参数
        autoScreenShot: false,
        screenShotNum: 1,
        screenShotType: 1,
        screenShotMode:20,
        paraStudyTime: false,
        studyTime: ['00:00:00', '23:59:00'],
        paraAnalysisLimitTime: "", // 课后习题限时
        paraUnitTestLimitTime: "", // 单元测试限时
        paraExamLimitTime: "", // 结业考试限时
        paraExamLimitMinTime: "",
        paraAnalysisLimitMinTime: "",
        paraIsLimitIp: false,
        convertClassHourType:"10", // 档案学时设置
        convertClassHour: '45', // 档案课时计算方式
      },
      // ProgressDisabled: true, // 完成进度输入框禁用状态
      trainTypeList: [], // 培训类型
      convertClassHourList: [], // 档案分钟转换课时数
    };
  },
  created() {
    this.getareatree();
    this.getTraintype();
    this.id = this.$route.query.paraId;
    this.stu = this.$route.query.stu;
    this.getDetail(this.id);
    this.getLearningstateList();
  },
  methods: {
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getLearningstateList() {
      const LearningstateList = this.$setDictionary("CROWDTYPE", "list");
      const list = [],
        list2 = [{ value: "00", label: "全部" }];
      for (const key in LearningstateList) {
        list.push({
          value: key,
          label: LearningstateList[key],
        });
        list2.push({
          value: key,
          label: LearningstateList[key],
        });
      }
      this.LearningstateList = list;
      this.LearningstateList2 = list2;
      const ScreenList = this.$setDictionary("PROJECT_PARAMETER_SCREEN_SHOT_TIME", "list");
      for (const key in ScreenList) {
        this.ScreenList.push({
          value: key,
          label: ScreenList[key],
        });
      }
      // 档案分钟转换课时数
      for (const key in this.$setDictionary("CONVERT_CLASS_HOUE", "list")) {
        this.convertClassHourList.push({
          value: key,
          label: this.$setDictionary("CONVERT_CLASS_HOUE", "list")[key],
        });
      }
    },

    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    cancle() {
      this.$router.back();
    },
    getDetail(paraId) {
      this.$post("/run/project/course/param/query", { paraId }).then((res) => {
        if (res.status == "0") {
          this.Form = {
            ...res.data,
            paraTimeLimit: JSON.parse(res.data.paraTimeLimit || "[]"),
            studyLength: res.data.paraTimeLimit !== "[{}]" ? true : false,
            paraCompleteConditionT:
              res.data.paraCompleteCondition == null ||
                res.data.paraCompleteCondition <= 0
                ? false
                : true,
            paraCompleteType: res.data.paraCompleteType,

            paraCompleteConditionNum: res.data.paraCompleteCondition
              ? res.data.paraCompleteCondition
              : "80",
            paraRollRandomNum: res.data.paraRollRandomNum || "2",
            screenShotType: res.data.screenShotType
              ? res.data.screenShotType.toString()
              : "1",
            screenShotNum: res.data.screenShotNum
              ? res.data.screenShotNum
              : "5",
            xxsjlx: res.data.studyTimeLimitType,
            paraStudyTime: res.data.studyStartTime ? true : false,
            studyTime: [res.data.studyStartTime, res.data.studyEndTime],

            paraExamNumLimit:
              res.data.paraExamNumLimit ||
              false,
            paraExamMaxNum:
              res.data.paraExamMaxNum,
            paraExamHint:
              res.data.paraExamHint,
            retakesAllowedNumber:
              res.data.retakesAllowedNumber,
            paraIsLoginEveryDayMode: res.data.paraIsLoginEveryDayMode ?? 10,
            paraIsLoginEveryDayPopupFirst: res.data.paraIsLoginEveryDayPopupFirst ?? false,
            paraIsLoginEveryDayPopupMessage: res.data.paraIsLoginEveryDayPopupMessage ?? '根据上海监管要求，请确保为本人学习！',
            paraIsLoginMode: res.data.paraIsLoginMode ?? 10,
            paraIsLoginPopupMessage: res.data.paraIsLoginPopupMessage ?? '根据上海监管要求，请确保为本人学习！',
            paraIsEventLimit: res.data.paraIsEventLimit ?? false,
            paraIsEventLimitTime: res.data.paraIsEventLimitTime ?? 1,
            paraIsEventLimitMode: this.Form.paraIsEventLimitMode ?? 10,
            idCardVerifyMode: res.data.idCardVerifyMode ?? 10,
            convertClassHourType: String(res.data.convertClassHourType) || "10",
            convertClassHour: String(res.data.convertClassHour) || "45",
          };
          if (res.data.paraIsSnapshots && !res.data.paraTimeMode) {
            this.Form.paraTimeMode = '10'
          } else if (res.data.paraIsSnapshots && res.data.paraTimeMode) {

            this.Form.paraTimeMode = '' + res.data.paraTimeMode
          }
          if (!this.Form.paraTimeLimit.length) {
            this.Form.paraTimeLimit.push({ ...this.Form.obj });
          }
          this.areaName = "默认";
          this.trainTypeName = "默认";
        }
      });
    },
  },
};
</script>
<style lang="less" scope>
.seeClassCurriculumParameterInfo {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
      width: 100%;
      padding-left: 10rem;
    }

    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }

  .paraCompleteConditionInput {
    display: inline-block;

    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }

  .el-input.is-disabled .el-input__inner {
    background: transparent;
  }

  .el-radio__input.is-disabled .el-radio__inner,
  .el-radio__input.is-disabled.is-checked .el-radio__inner {
    background: transparent;
  }
}
</style>
